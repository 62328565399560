// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Test(props) {
    const { tint } = props
    const [loading, setLoading] = useState(true)
    const [character, setCharacter] = useState()

    useEffect(() => {
        function fetchData() {
            const url = "https://onepiecelogs.dev/api/characters/roronoa_zoro"
            const response = fetch(url)
                .then((result) => result.json())
                .then((result) => {
                    setCharacter(result.json)
                    setLoading(false)
                })
        }
        fetchData()
    }, [])

    return (
        <div style={{ width: 300, height: 300, backgroundColor: "#fff" }}>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <p>your character is {character.name}</p>
            )}
        </div>
    )
}

addPropertyControls(Test, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
        defaultValue: "#09F",
    },
})

// Styles are written in object syntax
// https://react.dev/reference/react-dom/components/common#usage
const boxStyle = {
    margin: 25,
    width: 125,
    height: 125,
    borderRadius: 25,
}
